import axios from "axios";

const fetchBydate = async function (inicio, empresa) {
    try {
        const response = await axios.get(`/api/dates_venta/${inicio}/${empresa}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchBydate;