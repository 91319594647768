import axios from "axios";

const fetchNVenta = async function (empresa) {
    try {
        const response = await axios.get(`/api/fetch_venta/${empresa}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchNVenta;