import fetchAll from "./fetchAll";
import fetchByVoucher from "./fetchByVoucher";
import fetchBydate from "./fetchBydate";
import cancel from "./cancel";
import fetchByFile from "./fetchByFile";
import fetchNVenta from "./fetchNVenta";

export default {
    fetchAll,
    fetchByVoucher,
    fetchBydate,
    cancel,
    fetchByFile,
    fetchNVenta
}